<template>
  <aside class="carPricing p-2">
    <section v-if="hasMotabilityPrice">
      <div class="carPricing__items">
        <div
          v-if="vehicle.Motability!.CivilianAdvanceRental"
          class="row align-baseline"
        >
          <div class="col text-base">Advance Payment</div>
          <div class="col-auto">
            <OsPrice
              :price="formatOTRPrice(vehicle.Motability!.CivilianAdvanceRental)"
            />
          </div>
        </div>
        <div
          v-if="vehicle.Motability!.WPMSAdvancePayment"
          class="row align-baseline"
        >
          <div class="col text-base">WPMS</div>
          <div class="col-auto">
            <OsPrice
              :price="formatOTRPrice(vehicle.Motability!.WPMSAdvancePayment)"
            />
          </div>
        </div>
      </div>
    </section>

    <section v-if="!hasOffer && hasPrice">
      <div class="carPricing__items">
        <div v-if="hasPrice" class="row align-baseline">
          <div class="col text-base">{{ priceLabel }}</div>
          <div class="col-auto">
            <OsPrice :price="formattedPrice" />
          </div>
        </div>

        <div v-if="showP11d" class="row align-baseline">
          <div class="col text-base">P11D value</div>
          <div class="col-auto">
            <OsPrice :price="formatOTRPrice(vehicle.P11D)" is-small />
          </div>
        </div>
      </div>
    </section>

    <section v-if="hasOffer">
      <div class="carPricing__items">
        <div class="row align-baseline">
          <div class="col text-base">{{ priceLabel }}</div>
          <div class="col-auto">
            <OsPrice :price="formattedPrice" is-strike-through is-small />
          </div>
        </div>

        <div v-if="showP11d" class="row align-baseline">
          <div class="col text-base">P11D value</div>
          <div class="col-auto">
            <OsPrice :price="formatOTRPrice(vehicle.P11D)" is-small />
          </div>
        </div>

        <div v-if="hasCampaignOffer" class="row align-baseline">
          <div class="col text-base">Manufacturer offer</div>
          <div class="col-auto">
            <OsPrice :price="vehicle.CampaignOfferContribution!" is-small />
          </div>
        </div>

        <div v-if="hasRetailerOffer" class="row align-baseline">
          <div class="col text-base">Retail offer</div>
          <div class="col-auto">
            <OsPrice :price="vehicle.RetailerOfferContribution!" is-small />
          </div>
        </div>

        <div v-if="hasCampaignOLEVOffer" class="row align-baseline">
          <div class="col text-base">Government OZEV grant</div>
          <div class="col-auto">
            <OsPrice :price="vehicle.CampaignOLEVOfferContribution!" is-small />
          </div>
        </div>

        <div
          v-if="vehicle.PromoCode && vehicle.CampaignPromoOfferContribution"
          class="row align-baseline"
        >
          <div class="col text-base">{{ vehicle.PromoCode }}</div>
          <div class="col-auto">
            <OsPrice :price="vehicle.CampaignPromoOfferContribution" is-small />
          </div>
        </div>

        <div v-if="showOfferPrice" class="row align-baseline">
          <div class="col text-base">{{ offerPriceLabel }}</div>
          <div class="col-auto">
            <OsPrice :price="retailerOfferPrice" />
          </div>
        </div>
      </div>

      <p v-if="includeTerms" class="text-sm text-gray-400 mt-6">
        †Get this offer if you {{ isNew ? 'order' : 'reserve' }} by
        {{ formatDate(vehicle.OfferExpiryDate) }}.
        <NuxtLink
          to="/page/offers-terms-conditions"
          class="text-blue-600 bold"
          target="_blank"
        >
          T&Cs apply
        </NuxtLink>
        .
      </p>
    </section>
  </aside>
</template>

<script lang="ts">
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { isNewVehicle, isUsedVehicle } from '~/guards/vehicle'

export default {
  name: 'VehiclePricing',

  props: {
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: true,
      default: () => ({})
    },
    includeTerms: {
      type: Boolean,
      default: true
    },
    showOfferPrice: {
      type: Boolean,
      default: true
    }
  },

  setup(props) {
    const customerTypeStore = useCustomerTypeStore()
    const { formatDate } = useDate()
    const { isNew } = useVehicle(toRef(props, 'vehicle'))

    const hasPrice = computed<boolean>(() => {
      if (isNewVehicle(props.vehicle)) {
        return !hasOffer.value && !!props.vehicle.OTR
      }

      return !!props.vehicle.RetailPrice
    })

    const priceLabel = computed<string>(() => {
      if (isNewVehicle(props.vehicle)) {
        return 'On the road price'
      }

      return 'Retail price'
    })

    const formattedPrice = computed<string>(() => {
      if (isNewVehicle(props.vehicle)) {
        return formatOTRPrice(props.vehicle.OTR)
      }

      return formatRetailPrice(props.vehicle.RetailPrice)
    })

    const hasMotabilityPrice = computed(() => {
      return !!(
        props.vehicle?.Motability?.CivilianAdvanceRental ||
        props.vehicle?.Motability?.WPMSAdvancePayment
      )
    })

    const hasOffer = computed<boolean>(() => {
      return parseTotalOffer(props.vehicle?.TotalOfferValue) > 0
    })

    const offerPriceLabel = computed<string>(() => {
      return isNew.value ? 'Offer price†' : 'Retail offer price†'
    })

    const retailerOfferPrice = computed<string>(() => {
      if (isNewVehicle(props.vehicle) && props.vehicle.OTR) {
        return formatOTRPrice(props.vehicle.ActualPrice)
      } else if (isUsedVehicle(props.vehicle) && props.vehicle.RetailPrice) {
        return formatOTRPrice(props.vehicle.ActualPrice)
      }

      return 'N/A'
    })

    const hasCampaignOffer = computed<boolean>(() => {
      return (props.vehicle?.CampaignOfferContribution || 0) > 0
    })

    const hasRetailerOffer = computed<boolean>(() => {
      return (props.vehicle?.RetailerOfferContribution || 0) > 0
    })

    const hasCampaignOLEVOffer = computed<boolean>(() => {
      return (props.vehicle?.CampaignOLEVOfferContribution || 0) > 0
    })

    const showP11d = computed<boolean>(() => {
      return (
        customerTypeStore.isBusiness &&
        !!props.vehicle.P11D &&
        isNewVehicle(props.vehicle)
      )
    })

    return {
      hasPrice,
      hasMotabilityPrice,
      offerPriceLabel,
      hasOffer,
      isNew,
      formattedPrice,
      retailerOfferPrice,
      priceLabel,
      hasCampaignOffer,
      hasRetailerOffer,
      hasCampaignOLEVOffer,
      showP11d,
      formatDate
    }
  }
}
</script>

<style lang="scss" scoped>
.carPricing {
  :deep(.price) {
    .price__label,
    .price__value {
      line-height: 1;
      white-space: nowrap;

      &.small,
      &.strike-trough {
        color: $grey--darkest;
      }
    }
  }
}

.carPricing__items {
  color: $grey--darkest;

  .row + .row {
    margin-top: 4px !important;
  }
}

.carPricing__copy {
  color: $grey--dark;
  font-family: $mb-font-text;
  margin-top: rem(24);

  a {
    font-size: rem(14);
    margin-right: rem(4);
  }

  span {
    width: 100%;
    display: inline-block;
    padding-top: rem(4);
  }
}
</style>
